.contact-tab-css {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin-bottom: 0;
  /* justify-content: center; */
}

.contact-active-tab-css {
  background-color: white;
  color: blue;
  font-weight: 900;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid blue;
  padding: 0.5rem 1.5rem;
  /* cursor: pointer; */
}
.contact-Inactive-tab-css{
  font-weight: 600;
  /* border: 1px solid #c9c9c9; */
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.global-pink-course{
  border-bottom: 2px solid purple;
  color:purple
}

.global-blue-course{
  border-bottom: 2px solid rgb(0, 120, 225);
  color:rgb(0, 120, 225);
}
.global-green-course{
  border-bottom: 2px solid green;
  color:green;
}
.global-orange-course{
  border-bottom: 2px solid rgb(217, 61, 4);
  color:rgb(217, 61, 4);
}

.global-purple-course{
  border-bottom: 2px solid rgb(135, 22, 235);
  color:rgb(135, 22, 235)
}

.global-pink-heading{

  color:purple
}

.global-blue-heading{

  color:rgb(0, 120, 225);
}
.global-green-heading{

  color:green;
}
.global-orange-heading{

  color:orangered;
}

.global-purple-heading{

  color:rgb(135, 22, 235)
}

.course-active-tab-css {
  background-color: white;
  font-weight: 900;
  border-radius: 5px 5px 0 0;
  /* border-bottom: 2px solid blue; */
  padding: 0.5rem 1.5rem;
  /* cursor: pointer; */
}