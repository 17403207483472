/* Header page */
.header {
  background-color: #fff;
}

.header .header-user-logo {
  position: relative;
}

.header .logo img {
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  top: -30px;
  cursor: pointer;
}

.header .logo .sub-title {
  left: 73px;
  position: absolute;
  overflow: visible;
  white-space: nowrap;
  line-height: 49px;
  margin-top: -17.5px;
  text-align: left;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(10, 10, 10, 1);
  text-transform: capitalize;
}

.header .dashed-input {
  border: 2px dashed #b4b4b4;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 1.02px;
  opacity: 0.65;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.header .dashed-input:focus {
  outline: none !important;
}

.header .dashed-input::placeholder {
  text-align: center;
}

.header .navmenu button {
  margin: 0 3px;
  font-family: Open Sans;
  font-weight: bold;
}

#dropdown-hide {
  appearance: none;
}

.fa-w-16 {
  font-size: 12px;
  /* color: tomato; */
}

.is-dark-theme svg {
  color: white;
}
.nav-link-custom:hover {
  color: #0084fb !important;
}
.is-dark-theme .sticky-top {
  border-bottom: #fffefe;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.is-dark-theme .fixed-top {
  border: #ff6600;
  border-bottom: #fffefe;
}

.is-dark-theme .nav-link {
  color: white;
}

.top-border {
  min-height: 30px;
}

.profile-img-dropdown .dropdown-toggle::after {
  opacity: 0;
}

.toggle-opacity-css .dropdown-toggle::after {
  opacity: inherit !important
}

.profile-dropdown-item a {
  color: #212529 !important;
  font-weight: 400 !important;
  padding: 7px 7px 7px 0 !important;
}

.img-padding {
  margin-top: -18px;
}

@media (max-width: 920px) {
  .header .row .tjunction-logo {
    width: 100%;
    text-align: center;
  }

  .profile-dropdown.navbar-nav .profile-title-dropdown .dropdown-menu {
    left: -65px !important;
  }

  .profile-dropdown.navbar-nav .user-menu .dropdown-menu {
    left: -105px !important;
  }

}

@media (max-width: 992px) {
  .logo-marginleft {
    margin: 0 10px !important;
  }
}

/* @media (max-width:450px) {
  .logo-marginleft {
    margin: 0 -15px !important;
  }
} */

.header .greeting-text {
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.02px;
  font-size: 20px;
}

.header .greeting-text span {
  font-weight: bold;
}

.header .learnhub-img {
  padding: 5px 30px;
  text-align: center;
  border: 2px dashed #b4b4b4;
}

.header .learnhub-img img {
  max-height: 35px !important;
}

.profile-dropdown {
  position: relative;
}

.profile-dropdown .dropdown {
  position: absolute;
  margin-top: -48px;
  font-size: 19px;
  right: -30px;
}

.profile-dropdown .profile-title-dropdown {
  font-size: 13px;
  margin-top: 4px;
}

.profile-dropdown .dropdown>a {
  color: #000;
}


.header-data {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.header-data .logo,
.header-data .profile-icon {
  width: 25%;
}

.header-data .name-type-text {
  width: 50%;
}

@media (max-width: 767.98px) {

  .header-data .logo,
  .header-data .profile-icon {
    width: 50%;
  }

  .header-data .name-type-text {
    width: 100%;
  }

  header .learnhub-img {
    padding: 0 !important;
  }

  .header .learnhub-img img {
    max-height: 25px !important;
  }

  .profile-dropdown {
    top: 0 !important;
  }

  .header .greeting-text {
    padding: 25px 10px 5px;
    font-size: 12px;
  }
}

/* .profile-icon {
  order: -1;
  flex: 1 0 100%;
} */

.profile-active::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 17px;
  width: 5px;
  height: 11px;
  border: solid #ff6600;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.header .tjunction-logo img {
  max-height: 50px;
}

.header .header-search-bar {
  position: absolute;
  left: 269px;
}

@media (max-width: 576px) {
  .header .header-search-bar {
    position: relative;
    left: initial;
  }
}

.header .header-profile-pic {
  height: 55px;
  width: 55px;
  border-radius: 15px;
}

.profile-dropdown .user-menu a::after {
  position: absolute;
  left: 25px;
  top: 33px;
  opacity: 0;
}

.profile-dropdown.navbar-nav .user-menu .dropdown-menu {
  left: -85px;
}

.profile-dropdown.navbar-nav .profile-title-dropdown .dropdown-menu {
  left: -55px;
}

.header-search-bar .search-icon {
  position: absolute;
  left: 10px;
  top: 11px;
  color: #87898a;
  font-size: 12px;
}

.header-search-bar input {
  text-indent: 15px;
  border-radius: 1.25rem;
  font-size: 13px;
}

.is-dark-theme header .search-icon {
  background-color: #2c2e30 !important;
}

.is-dark-theme .header .navmenu a {
  color: #fff;
}

.is-dark-theme .header {
  background-color: #000;
}

.header .navbar-toggler {
  border: none;
}

.header .navbar-toggler:focus {
  box-shadow: none;
}

.header .navbar-nav .dropdown-menu {
  position: absolute;
}

.side-dropdown {
  position: absolute;
  top: 87px;
  width: 170px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: "0px 4px 8px rgba(0, 0, 0, 0.1)";
  z-index: 1000;
  margin-left: 5px;
}

@media (min-width: 1400px) {
  .nav-menu-margin {
    margin-right: 10.3rem !important;
  }
}

@media (min-width: 1201px) {
  .nav-menu-margin {
    margin-right: 9.7rem;
  }
}