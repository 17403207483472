.alert-content-css {
  margin: 0 10px;
  font-size: 14px;
}

.alert-icon-css {
  width: 20px;
  height: 24px;
}

.alert-close-icon {
  width: 17px;
  height: 15px;
  cursor: pointer;
}

.success-icon {
  color: green;
}

.danger-icon {
  color: green;
}

.warning-icon {
  color: green;
}

.header-menu-icon {
  width: 15px;
  margin-right: 5px;
  height: 20px;
}