.features-tab {
  padding-left: 52px;
  padding-bottom: 2px;
  position: sticky;
  top: 53px;
  z-index: 10;
  margin-right: -10px;
}

.features-tab ul li {
  top: -7px;
  position: relative;
  /* font-family: "Nunito", sans-serif; */
  font-family: 'Open Sans';
  font-size: 19px;
  font-weight: 600;
  padding-right: 20px;
}

.features-tab ul li span {
  position: absolute;
  padding-left: 10px;
  color: #656565 !important;
}


.tab-circle-minus {
  max-width: 15px;
  margin-left: 5px;
  top: 19%;
  position: absolute;

}

.ads-view {
  background: #9B9B9B;
  opacity: 0.8;
  background: repeating-linear-gradient(-45deg, #9B9B9B, #9B9B9B 5px, #e5e5f7 5px, #e5e5f7 25px);
  height: 250px;
  margin-bottom: 10px;
  border-radius: 7.8px;
  width: 300px;
}

.ads-view img {
  width: 100%;
  height: 100%;
  border-radius: 7.8px;
}

.post-details-area .post-details-headers {
  padding: 5px 15px 5px 15px;
  /* margin-left: 40px !important; */
  border-radius: 7.8px;
  /* height: 30px; */
  /* position: sticky; */
  /* z-index: 10; */
  /* top: 150px; */
}

/* .post-details-area {
  margin-top: 90px;
} */

.post-details-area .post-details-content {
  margin-left: 40px !important;
  border-top: none;
}

.main-frame {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 40px;
  margin-top: -6px;
  margin-left: 0px;
}

.ads-frame {
  /* padding-top: 122px; */
  padding: 95px 0px 0px 0px !important;
  width: 300px !important;
}

.post-details-headers ul {
  margin-bottom: 0 !important;
}

.post-details-headers ul li {
  padding: 0px 20px 0px 20px;
  color: #fff;
  font-size: 15px;
}

.post-details-area .title-label {
  position: absolute;
  width: 10px;
  height: 100vh;
  padding: 100px 0;
}


.post-details-area .for-profile {
  position: fixed;
  /* align: end; */
  padding-left: 1465px;
}

.for-profile .title-label {
  position: fixed;
  /* margin-left: 1468px; */
  right: 0;
  width: 10px;
  height: 100vh;
}


.post-details-area .title-label span {
  /* height: 100px; */
  z-index: 101;
  font-weight: bold;
  transform: rotate(180deg);
  padding: 5px 1px 5px 3px;
  margin-left: -11px;
  margin-right: 8px;
  margin-top: 20px;
  text-transform: capitalize;
  font-size: 13px;
  writing-mode: vertical-rl;
  letter-spacing: 1px;
  /* background-color:#094986; */
  text-align: center;
}

.post-details-area .title-label .for-profile span {
  height: 100px;
  z-index: 101;
  font-weight: bold;
  transform: rotate(180deg);
  padding: 5px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 10px;
  writing-mode: vertical-rl;
  letter-spacing: 0.2px;
  background-color: #fff;
  text-align: center;
}

.post-details-area .title-label span:hover {
  background-color: #fff !important;
  color: #000 !important;
  cursor: pointer;
}

.post-details-area .title-label .for-profile span:hover {
  background-color: #BEBEBE;
  cursor: pointer;
}

.features-tab ul li img {
  display: none;
}

.features-tab ul li:hover img {
  display: inline-block;
}

.layout-center-side {
  position: relative;
  top: 100px;
}


.post-query-input {
  padding: 15px;
  border-radius: 0.50em;
  background: #fff;
  box-shadow: 5px 0rem 1rem rgba(0, 0, 0, .15) !important;
  /* position: sticky; */
  z-index: 10;
  /* top: 180px; */
  /* margin-left: 40px !important ; */
  margin-bottom: 0px !important;
}

/* .post-query-input img {

  width: 36px;
  height: 36px;
  margin: 9px 10px;
  border-radius: 4px;
  cursor: pointer;

} */

.post-query-input input {
  margin-left: 15px;
  margin-right: 51px;
  border-radius: 0.75em;
}

.post-query-input input::placeholder {
  font-family: Open Sans;
  color: #5f5d5d;
  letter-spacing: 0.03px;
}

.post-queries-data {
  padding: 2px 0;
  z-index: 9;
}

.post-queries-data .position-relative {
  margin-top: 0px;
}

.post-queries-data .post-card {
  border: 0.5px solid #ebe8e8;
  background: #fff;
  border-radius: 0.78em;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  margin-bottom: 20px;
}

.post-card .post-card-body {
  padding: 10px 53px;
  font-size: 13px;
}

.post-queries-data .post-card .post-card-footer {
  border-top: 0.5px solid #ebe8e8;
  margin: 0 !important;
  font-size: 13px;
}

.post-queries-data .post-card .post-card-footer>div {
  padding: 15px 10px;
  cursor: pointer;
}

.post-queries-data .post-card .post-card-header {
  border-bottom: 0.5px solid #ebe8e8;
}

.post-queries-data .post-card .post-card-header .profile {
  margin-top: 10px;
}

.post-queries-data .post-card .post-card-header img,
.comment-profile img {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.post-queries-data .post-card .post-card-header .user-name {
  /* padding: 12px  10px; */
  font-weight: bolder;
  font-size: 13px;
  line-height: 12px;
  margin-bottom: 0;
  /* white-space: nowrap; */
}

.timeago {
  line-height: 12px;
  margin-bottom: 0;
}

.post-queries-data .post-card .post-card-header .row {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.post-queries-data .post-card .post-card-header .role {
  text-align: center;
  padding: 5px;
}

.post-queries-data .post-card .post-card-header .review {
  font-size: 12px;
  top: -2px;
  position: relative;
}

.post-queries-data .post-card .post-card-header .category {
  background-color: #0A0A0A20;
  text-align: center;
  word-break: break-word;
}

.post-queries-data .post-card .post-card-header .date-view {
  text-align: center;
  word-break: break-word;
}

.post-queries-data .post-card .post-card-header .show-more-view {
  width: 10px !important;
  order: -1;
  right: 20px;
  top: 5px;
  position: absolute;
}

.show-more-view {
  margin-top: -3px;

}

.show-more-view>a {
  writing-mode: vertical-rl;
  font-weight: bold;
  font-size: 20px;
  opacity: 1 !important;
  cursor: pointer;
  color: #808182 !important;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.show-more-view .dropdown-menu {
  border-radius: 12px;
  min-width: fit-content !important;
  inset: -16px 0px auto auto !important;
  transform: translate(0px, 38px) !important;
  z-index: 1;
  overflow: visible;
}

.height-18px {
  height: 18px;
}


.show-more-view .dropdown-menu a {
  color: #777;
}

.show-more-view .dropdown-toggle::after {
  opacity: 0;
}

.post-queries-data .post-card .post-card-footer img {
  max-height: 18px;
}

.post-queries-data .post-card .post-card-footer .answer-img {
  margin-top: -2px;
  height: 13px;
}

.post-queries-data .post-card .post-card-footer .comment-img {
  margin-top: 2px;
  height: 13px;
}

.post-queries-data .post-card .post-card-footer i,
.comment-data .post-box-answer i {
  color: #3a71ca;
  font-size: 13px;
}

.post-queries-data .post-card .post-card-footer .smilies-view,
.post-queries-data .post-card .post-card-footer .comment-view {
  text-align: center;
}


#qboard-post textarea {
  padding: 15px;
  margin-right: 5px;
}

.bootstrap-tagsinput {
  margin: 15px auto;
}

#qboard-post input[type=file] {
  display: inline-block !important;
}

.bootstrap-tagsinput .tag {
  margin: 2px;
  padding: .2em .6em .3em;
  color: #4285F4;
  border-radius: 10px;
  background-color: #BBBBBB80;
  font-size: 13px;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}

.qboard-file-details {
  font-size: 13px;
}

.qboard-file-details span {
  font-size: 16px !important;
  padding-top: 4px !important;
  cursor: pointer !important;
}

#qboard-post .modal-footer,
#requirementTrainingModal .modal-footer {
  display: inline;
  padding: 20px 90px 40px 90px;
}

#qboard-post .modal-footer .posticons .attach-icon,
#requirementTrainingModal .posticons .attach-icon {
  padding-right: 7px;
}

#qboard-post .modal-footer .posticons div,
#requirementTrainingModal .modal-footer .posticons div {
  padding: 7px;
}

#qboard-post .modal-footer .posticons img,
#requirementTrainingModal .modal-footer .posticons img {
  max-height: 35px;
}

#qboard-post .error-message {
  margin-top: -12px;
}

#qboard-post .modal-footer button {
  max-height: 80%;
}

@media (max-width: 992px) {

  #qboard-post .modal-footer,
  #requirementTrainingModal .modal-footer {
    display: inline;
    padding: 1px 30px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .ads-view {
    display: none;
  }

  .post-details-area {
    margin-right: -323px;
  }

  .bg-color-changes {
    position: sticky;
    top: 56px !important;
    padding-bottom: 10px;
    margin-left: -30px;
  }
}

@media (max-width: 576px) {
  #qboard-post .modal-footer .posticons {
    text-align: center;
  }

}

.post-answer-queries {
  position: relative;
  background-color: #fff;
  border-radius: 7.8px;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.post-answer-queries img {
  max-height: 36px;
  /* width: 30px; */
  margin: 10px 15px 10px 15px;
  border-radius: 50%;
  cursor: pointer;
}

.post-answer-queries input {
  height: 35px;
  border-radius: 0.75em;
  margin: 10px 66px 10px 0;
  font-size: 13px;
}

.answer-padding {
  padding: 0.5rem 0.5rem 0 0.5rem;
  cursor: pointer;
}

.post-answer-view,
.post-comment-view {
  font-size: 13px;
  border-radius: 1.25rem;
  margin-left: 30px;
  margin-right: 10px;
}

.post-comment-view {
  background-color: #fff;
}

.reply-count {
  cursor: pointer;
  font-size: 12px;
}

.post-answer-view {
  margin-top: 5px;
}

.post-card+ul+.post-comment-view {
  margin-top: 5px;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.post-answer .post-card {

  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 0;
}

.post-answer .post-card .post-card-header {
  border-bottom: none;
}

.post-answer .post-card .post-card-footer {
  border-top: none;
}

.post-comment-view>div {
  width: auto;
}

.post-comment-view .comment-data {
  padding: 10px;
}

.comment-profile {
  position: relative;
}

.comment-data .show-more-view {
  float: right;
}

.comment-data .show-more-view .dropdown-toggle {
  padding-right: 0;
}

.comment-profile .profile h6 {
  padding: 10px;
  font-weight: bolder;
  font-size: 13px;
}

.comment-data .date-view {
  font-size: 13px;
}

.comment-data .show-more-btn em {
  writing-mode: vertical-rl;
  font-weight: bold;
}

.comment-data .comment-img {
  max-height: 12px;
  margin-top: 2px;
}

.post-comment-view .post-comment-view {
  padding-right: 0 !important;
}

.show-more-smilies {
  background: #fff;
  list-style-type: none;
  position: absolute;
  z-index: 101;
  border-radius: 12px;
  border: 0;
  box-shadow: 0 2px 8px #b0a9a9;
  padding: 5px 10px !important;
  width: -moz-fit-content !important;
  width: max-content !important;
  margin-top: -35px;
  left: 20%;
  cursor: pointer;
}

.show-more-smilies span {
  display: initial !important;
  background-image: initial !important;
}

.comment-data .show-more {
  margin-left: -11px;
  margin-top: 5px;
}

.children.show-more-smilies {
  left: 75px !important;
}

.comment-data .show-more-smilies {
  left: 10%;
  margin-top: -29px !important;
}

.smilies-comment span {
  display: initial !important;
  background-image: initial !important;
}

.smilies-comment .smilie-like-count {
  position: relative;
  margin-left: -2px;
}

.filter-data div>i {
  padding: 3px 4px 0 9px;
}

.category-select {
  font-family: 'Open Sans';
  font-size: 12px;
}

.post-card .file-download {
  word-break: break-all;
}

.post-card-header .user-details {
  padding: 9px 0 !important;
  font-size: 14px;
}

@media (max-width: 770px) {
  .filter-data {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 1150px) {
  .post-card-header .col-md-6 {
    width: 50%
  }
}

.post-card-header ul>.col-md-6 {
  padding: 0;
}

@media (max-width: 992px) {
  .ads-frame {
    display: none;
  }

  .post-card-header .col-md-6 {
    width: 100%
  }

  .main-frame {
    margin-left: 0;
    margin-right: 0;
  }

  .is-expand .main-frame,
  .post-details-area .post-details-headers,
  .post-details-area .post-details-content {
    margin-left: 0 !important;
  }

  .features-tab {
    padding-left: 42px;
    margin-left: -40px;
  }

  .features-tab {
    top: 80px;
  }

  .features-tab ul li:hover img {
    display: none;
  }

  .post-details-area .post-details-headers {
    top: 218px;
  }

  .post-query-input {
    top: 248px;
  }

  .layout-center-side {
    position: relative;
    top: 79px;
  }

  /* .primary-bg-color {
  background-color: transparent !important;
} */
}

@media (max-width: 768px) {

  .features-tab ul li .qboard-sidebar img {
    display: none;
  }

  .post-details-headers ul li {
    padding: 0 10px
  }

  .primary-tab .post-details-area {
    padding: 0 !important;
  }

  .primary-tab .features-tab {
    padding: 0 !important;
    margin-left: 10px !important;
  }
}

.is-expand .main-frame {
  margin-left: 0px;
}

@media (max-width: 576px) {
  .post-queries-data {
    padding: 2px !important;
  }

  .post-details-area .title-label,
  .features-tab ul li img {
    display: none !important;
  }

  .post-details-area .post-details-headers,
  .post-details-area .post-details-content {
    margin-left: 0 !important;
  }

}

.like-details-view {
  width: 100%;
  display: flex;
  border-bottom: 2px #e9ecef solid;
}

.like-details-view .smilies-comment>span {
  margin: 0 !important;
}

.like-details-view .smilies-comment.active-smilie-view {
  border-bottom: 5px #198754 solid;
}

.like-details-view .smilies-comment {
  font-size: 18px;
  padding: 2px 15px;
  cursor: pointer;
}

.like-details-view .smilie-like-count {
  margin-left: 0;
}

.liked-user-list {
  overflow-y: auto;
  max-height: 300px;
}

.liked-user-list li {
  border-bottom: 2px #e9ecef solid;
  padding: 5px 0;
}

.post-card-body .describe-details {
  word-break: break-all;
}

.filter-data>div {
  float: right !important;
}

.job-application-view div {
  padding: 5px;
  word-break: break-all;
  font-size: 14px;
}

#job-application-modal .modal-body {
  height: 300px;
  width: 100%;
  overflow-y: auto;
}

.liked-user-list li a {
  text-decoration: none;
}


.liked-user-list li h6 {
  margin-bottom: 0;
  color: #000
}

.liked-user-list li .smilies-comment span {
  font-size: 12px !important;
  top: 0 !important;
}

.youtube-img {
  padding-left: 7px;
}

.youtube-video .react-player {
  padding-bottom: 0px;
  position: relative;
  /* padding-bottom: 56.25%; */
  padding-top: 0px;
  height: 0;
}

.react-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.qboard-file-details .file-details {
  border: 1px solid #ced4da;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 5px 4px;
  margin-right: 5px;
}

#qboard-post .qboard-file-details .display-order {
  height: 10px;
}

#qboard-post .display-order {
  width: 50px;
  text-align: center;
}

#qboard-post .display-category {
  width: 40%;
  margin-right: 5px;
}

#qboard-post .qboard-file {
  margin-right: 5px;
}

@media (max-width: 576px) {
  .post-card-header .col-sm-6 {
    width: 50%
  }

  .post-card-header .col-sm-3,
  .post-card-header .col-sm-4,
  .post-card-header .user-details .col-sm-6 {
    width: 26%
  }

  .post-card-header .col-sm-1 {
    width: 10%;
  }

  .post-card-header .user-details {
    font-size: 10px !important;
  }
}

.bg-color-changes {
  position: sticky;
  top: 56px;
  padding-bottom: 10px;

}

.primary-bg-color {
  background-color: #f0f2f5;
}

.colour-changes {
  top: 40px;
  position: sticky;
  padding-bottom: 15px;
  /* padding-top: 17px; */
  background-color: #f2f9ff;
}

.features-tab ul {
  margin-bottom: 0 !important;
}

.features-tab-color {
  color: darkgray;
}

.primary-tab .post-details-area {
  margin-left: 0;
  padding: 0 20%;
}

.primary-tab .features-tab {
  padding: 0 21%;
  margin-left: 60px;
}

.primary-tab .features-tab ul li img {
  display: none;
}

.is-dark-theme .features-tab ul li {
  color: #b3b4b5;
}



.is-dark-theme .post-details-content,
.is-dark-theme .sticky-center,
.is-dark-theme .container-xxl.primary-bg-color,
.is-dark-theme .profile-deatils-view .quill,
.is-dark-theme .color-header.primary-bg-color,
.is-dark-theme .color-theme {
  background-color: #2c2e30 !important;
}

.is-dark-theme .card-header {
  background-color: #2c2e30 !important;
}

.is-dark-theme .header .search-form input {
  border-color: hsla(0, 7%, 91.6%, .34901960784313724) !important;
}

.is-dark-theme .d-theme svg,
.is-dark-theme .no-data-found {
  color: #fff !important;
}

.is-dark-theme .sidebar-edit {
  border: none !important;
}

.border-top-for-feed {
  border-top: 0.5px solid #ebe8e8;
}

.is-dark-theme .border-top-for-feed {
  border-color: hsla(0, 7%, 91.6%, .34901960784313724) !important;
}

.is-dark-theme .post-details-content .post-queries-data .position-relative .card .card-body,
.is-dark-theme .sticky-center .post-query-input,
.is-dark-theme .post-details-content .post-queries-data .post-card,
.is-dark-theme .post-details-content .post-query-input,
.is-dark-theme .post-answer-queries,
.is-dark-theme .post-comment-view,
.is-dark-theme .profile-deatils-view,
.is-dark-theme #leftMenuDiv .card-body,
.is-dark-theme .profile-deatils-view .card,
.is-dark-theme .profile-deatils-view input[type='range'] {
  background: black !important;
  color: #fff !important;
  border: none;
}

.is-dark-theme .post-details-content .post-queries-data .position-relative .card .card-body,
.is-dark-theme .post-details-content .post-queries-data .position-relative .card .card-header {
  background: black !important;
  color: #fff !important;
  border-color: hsla(0, 7%, 91.6%, .34901960784313724) !important;
}

.is-dark-theme input,
.is-dark-theme select,
.is-dark-theme textarea,
.is-dark-theme .tj-modal .file-details,
.is-dark-theme .dropdown-menu,
.is-dark-theme .ql-snow .ql-picker-options {
  background-color: #2c2e30 !important;
  color: #fff !important;
  border-color: #f1f1f13b;
}


.is-dark-theme input::placeholder,
.is-dark-theme textarea::placeholder,
.is-dark-theme .active-profile-view .profile-deatils-view p *,
.is-dark-theme .tab-content .profile-deatils-view *,
.is-dark-theme .interest-alert-text {
  color: #fff !important;
}

.is-dark-theme .profile-deatils-view .ql-snow .ql-stroke {
  stroke: #fff !important;
}

.is-dark-theme .header-search-bar input {
  background-color: #2c2e30 !important;

}

.is-dark-theme .show-more-smilies {
  background-color: #2c2e30 !important;
}

.is-dark-theme .post-card .post-card-header,
.is-dark-theme .post-queries-data .post-card .post-card-footer {
  border-color: #ebe8e859;
}

.is-dark-theme.tj-modal .modal-content {
  background: #2c2e30 !important;
  color: #fff !important;
}

.is-dark-theme.tj-modal {
  background: initial !important;
}

.notifications .dropdown-toggle::after {
  content: none;
}

.font-size-14px {
  font-size: 14px;
}


.for-profile {
  padding-right: 0px;
  /* margin-right: 20px; */
}

/* .dropdown-toggle::after{
  content:none;
} */

.for-logout {
  font-size: 15px;
  padding-right: 0px;
  margin-right: 11px;
}

.dropdown-hide {
  appearance: none;
}

.is-dark-theme input:-webkit-autofill,
.is-dark-theme input:-webkit-autofill:hover,
.is-dark-theme input:-webkit-autofill:active,
.is-dark-theme input:-webkit-autofill:focus {
  background-color: #2c2e30 !important;
  color: rgb(173, 169, 169) !important;
  -webkit-box-shadow: 0 0 0 1000px #2c2e30 inset !important;
  -webkit-text-fill-color: rgb(173, 169, 169) !important;
}

.is-dark-theme .bootstrap-tagsinput .tag {
  color: #fff !important;
}

.is-dark-theme .profile-top #leftMenuDiv {
  border: none;
}

.comment-data .post-box .describe-details {
  padding: 0 20px 0 50px;
}

.apply-badges .badge {
  padding: 5px;
}

.input-group .input-group-text {
  font-size: 12px;
}

.requirement-preview {
  font-size: 13px;
}

.requirement-preview .form-group * {
  padding-top: 2.5px !important;
  padding-bottom: 0 !important;
}

.job-box {
  font-size: 13px;
}

#requirement-training-modal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.is-dark-theme .features-tab,
.is-dark-theme .toogle-menu {
  background-color: #2c2e30 !important;
}

.toogle-menu {
  position: sticky;
  top: 23px;
  z-index: 1000;
  width: 40px;
  padding-left: 20px;
}

@media (max-width:425px) {
  .toogle-menu {
    top: 25px !important;
  }
}

.toogle-menu .dropdown-toggle::after {
  display: none;
}

.toogle-menu .dropdown-menu {
  padding: 0;
  border-radius: 1.25rem;
  inset: 0px auto auto auto !important;
}

.p-dropdown-panel {
  z-index: 1056 !important;
}

.p-treeselect-panel {
  z-index: 1056 !important;
}

.p-treeselect .p-treeselect-label {
  padding: 0.15rem 0.2rem;
}

.p-treeselect-label {
  color: #6c757d;
}

.p-treeselect-items-wrapper {
  max-height: 300px !important;
}

.p-treeselect-panel .p-treeselect-header {
  display: none !important;
}

.p-tree-container {
  padding-left: 0 !important;
}

.filter-category-css {
  width: 180px;
}

.qboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.qboard-header2-css {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.margin-right-css {
  margin-right: 1.0rem;
  cursor: pointer;
}

@media (max-width:992px) {
  .alert-top-css {
    position: relative;
    top: 78px !important;
  }
}

.alert-top-css {
  position: relative;
  top: 100px;
}