.main-panel {
	padding: 10px 30px;
}

/*modal-buttons */
.mb-button {
	border-radius: 4px;
	padding: 8px 16px;
	font-weight: bold;
	font-size: 14px;
}

.mb-button-confirm {
	background-color: #1877f2;
	/* Facebook blue */
	color: white;
	border: none;
}

.mb-button-confirm:hover {
	background-color: #1877f2;
	color: white;

}

.mb-button-cancel {
	background-color: #e4e6eb;
	/* Light gray background */
	color: #050505;
	border: none;
}

.mb-button-cancel:hover {
	background-color: #d8dadf;
}


/* left menu */
.list-product-nav a {
	font-size: 14px;
}

.list-product-nav a {
	display: block;
	text-decoration: none;
}

.list-product-nav a:hover,
.list-product-nav a:focus {
	color: #D52027;
}

.list-product-nav>li {
	border-bottom: solid 2px #fff;
	border: none;
}

.list-product-nav>li a.list-product-cat {
	color: #333;
	padding: 10px 20px;
}

.list-product-nav>li>a.list-product-cat:hover,
.list-product-nav>li>a.list-product-cat:focus {
	color: #000;
}

.list-product-subnav {
	display: none;
	border: solid 1px #e9e9e9;
}

.list-product-subnav li a {
	color: #000;
	padding: 6px 60px;
	font-size: 12px;
}

/* .prfl-details-none div {
	padding: 5px;
} */

.prfl-details-none input {
	padding-right: 5px;
}

.search-btn {
	position: absolute;
	right: 410px;
	top: 83px;
}

.border1 {
	border: 2px solid #e6e6e6;
}

.border2 {
	border: 2px solid #e6e6e6;
	height: 100%;
}

.post {
	background: #f2f6f9;
	width: 93%;
	border-radius: 25px;
	margin-left: 25px;
}

.list-inline-item:not(:last-child) {
	margin-right: .5rem;
}

.list-inline>li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
	cursor: pointer;
}

.list-inline {
	padding-left: 0;
	list-style: none;
	margin-left: -5px;
}

.list-inline-item {
	display: inline-block;
}

.row {
	margin-right: -15px;
	margin-left: -15px;
}

.starrating {
	font-size: 1em;
}

.loader {
	text-align: center;
	height: 50%;
}

.global-blue {
	/* background-color: #1d9bf0 !important; */
	background-color: #007bff !important;
	color: #fff !important;
	/* margin: 10px; */
}

.qboard-requirements {
	padding: 0 !important;
}

.post-input {
	width: 600px;
	outline: none;
	height: 40px;
	border-radius: 25px;
	border: .5px solid #333;
	text-indent: 10px;
}

.post-input::placeholder {
	font-size: 14px;
}

.tj-modal .modal-header {
	display: flex !important;
	border-bottom: none;
}

.tj-modal .modal-footer {
	border-top: none;
}

@media (min-width: 999px) {
	.tj-modal .modal-footer {
		border-top: none;
		padding: 1rem 10px 2rem 10px;
	}

	.tj-modal .job-modal-footer {
		border-top: none;
		padding: 70px 90px 40px 90px;
	}
}

.tj-modal .modal-header .modal-title {
	flex-grow: 1 !important;
	/* margin-top: 1rem; */
	margin-bottom: 1rem;
	color: rgba(33, 37, 41, 0.75);
	font-family: Nunito, sans-serif;
	font-size: 28px;
	font-weight: 700;
	padding: 2rem 0px 0px 24px;
}

/* label {
	margin-bottom: .5rem;
} */

.requirment-sub-details li {
	font-size: 12px;
	cursor: pointer;
}

.delete-moadal .modal-footer {
	justify-content: center !important;
}

.requirment-job-type {
	margin-left: 10px !important;
}

.requirement-preview label {

	font-weight: 500;
}

.moreinfo {
	text-decoration: none;
}

.tj-modal .close {
	background-color: #fff !important;
	border: none !important;
	font-size: 1.65rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
	margin-top: -60px;
}

.answer {
	list-style-type: none;
	margin-left: -5px;
	margin-right: 25px;
}

.post-query {
	width: 93% !important;
	margin-left: 26px;
}

.highlight-star {
	color: #F3D53D;
	/* padding-left: 11px; */
	cursor: pointer;
}

.dull-star {
	color: gray;
	/* padding-left: 11px; */
	cursor: pointer;
}

.show-more-btn em {
	font-size: 1rem;
	cursor: pointer;
}

.show-more {
	background: #fff;
	list-style-type: none;
	position: absolute;
	z-index: 101;
	border-radius: 12px;
	border: 0;
	box-shadow: 0 2px 8px #b0a9a9;
	padding: 0 !important;
	margin-left: -11px;
	margin-top: 5px;
}

.show-more li {
	padding: 5px 10px !important;
}

.star-rating-filter i.selected {
	color: #F4B30A;
	text-shadow: 0 0 1px #f48f0a;
}


.choose_file {
	position: relative;
	display: inline-block;
}

.choose_file.disable {
	opacity: 0.3;
	pointer-events: none;
}

.choose_file input[type="file"] {
	appearance: none;
	-webkit-appearance: none;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 40px;
	margin-top: 24px;
}

.posticons span {
	font-size: 20px;
}

.qboard-file-details span {
	font-size: 18px;
	cursor: pointer;
}


.post-description {
	font-size: 16px;
	margin-left: 25px;
}

.post-bottom {
	padding: 20px;
}

.post-head {
	font-size: 14px;
}

.absolute {
	position: absolute;
}

.hidden {
	/* The menu is hidden at first */
	display: none;
}

.pull-down {
	margin-top: -8px;
	margin-bottom: 12px;
	cursor: pointer;
}

.checkbox-container {
	display: inline;
}

.checkbox-container input {
	display: none;
}

.checkbox-container label {
	position: absolute;
	color: #6c757d !important;
	font-size: 12px;
	/* margin-left: -30px;
	margin-top: 17px; */
}

.checkbox-container label::before {
	display: inline-block;
	content: 'O';
	position: relative;
	top: 13px;
	left: -5px;
	width: 34px;
	height: 14px;
	padding: 0 0.5rem;
	font-size: 11px;
	line-height: 1.2rem;
	text-align: right;
	color: transparent;
	border: #ccc solid 1px;
	background-color: #4285F4;
	border-radius: 2rem;
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
	transition: all ease-in-out 0.3s;
}

.checkbox-container label::after {
	display: inline-block;
	content: '';
	position: absolute;
	left: 9px;
	top: 10.5px;
	width: 19px;
	height: 19px;
	border-radius: 1rem;
	background-color: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	transition: all ease-in-out 0.3s;
}

.checkbox-container input:checked+label::before {
	content: 'I';
	text-align: left;
	color: transparent;
}

.checkbox-container input:checked+label::after {
	left: 13px;
}

h5 .checkbox-container label::after {
	top: .1rem;
}

.checkbox-container1 {
	display: inline;
	/* margin-left: -35px; */
	margin-left: -15px;
	margin-top: -15px;
}

.checkbox-container1 input {
	display: none;
}

.checkbox-container1 label {
	position: absolute;
	color: #6c757d !important;
	font-size: 12px;
}

.checkbox-container1 label::before {
	display: inline-block;
	content: 'O';
	position: relative;
	top: 13px;
	left: -4px;
	width: 34px;
	height: 14px;
	padding: 0 0.5rem;
	font-size: 11px;
	line-height: 1.2rem;
	text-align: right;
	color: transparent;
	border: #ccc solid 1px;
	background-color: #4285F4;
	border-radius: 2rem;
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
	transition: all ease-in-out 0.3s;
}

.checkbox-container1 label::after {
	display: inline-block;
	content: '';
	position: absolute;
	top: 10px;
	left: -8.5px;
	width: 19px;
	height: 19px;
	border-radius: 1rem;
	background-color: #fff;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
	transition: all ease-in-out 0.3s;
}

.checkbox-container1 input:checked+label::before {
	content: 'I';
	text-align: left;
	color: transparent;
}

.checkbox-container1 input:checked+label::after {
	left: 13px;
}

.profile-bg-color {
	color: white;
}

.award-hide {
	margin-left: -6.5rem;
}

.lead {
	font-size: 13px !important
}

.font-weight-bold {
	font-weight: 700 !important;
}

.trash {
	cursor: pointer;
	color: darkgray;
}

.btn-brand {
	background-color: #0f52ba;
	color: white;
}

.btn-block {
	display: block;
	width: 100%;
}

.award-trash {
	margin-top: 9px;
	/* margin-left: 45px;
	margin-top: 28px; */
}

.col-centered {
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-top: 5px;
}

.job-trash {
	margin-top: 9px;
	/* margin-left: 45px;
	margin-top: 26px; */
}

.training-trash {
	margin-top: 9px;
	/* margin-top: 28px; */
	/* margin-left: 45px; */
}

.file-upload {
	position: relative;
	overflow: hidden;
	margin: 0px;
}

.file-upload input.upload {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	padding: 0;
	font-size: 20px;
	cursor: pointer;
	opacity: 0;
	filter: alpha(opacity=0);
}

.profile-form-label {
	font-size: 14px;
	margin: 8px;
}

.more-skill-trash {
	margin-top: 11px !important;
	margin-left: 10px !important;

}

.skill-check-label {
	margin-top: 2px !important;
	margin-left: 27px;
}

.end-label {
	margin-top: 4px !important;
}

.skill-trash {
	margin-top: 13px;
	margin-left: -16px;
}

.cke_bottom {
	padding: -1px 12px 0px !important;
	position: relative !important;
	border-top: none !important;
	background: none !important;
}

.sticky-btn {
	position: fixed;
	height: 49px;
	bottom: -19px;
	left: 301px;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}

.endorse-count {
	cursor: pointer;
}

.post-details-area .for-profile {
	position: fixed;
	padding-left: 1465px;
}

/* .right-padding {
	padding-right: calc(var(--bs-gutter-x) * 1.5);
} */

.about-me .profile-pic {
	position: absolute;
	left: 9px;
	top: 129px;
	width: 8rem;
	height: 8rem;
	border-radius: 6rem;
	cursor: pointer;
}

.about-me .profile-pic-css {
	/* position: absolute; */
	left: 9px;
	top: 129px;
	width: 8rem;
	height: 8rem;
	border-radius: 6rem;
	cursor: pointer;
}

.profile-round-image:hover .profile-pic-css {
	filter: grayscale(50%) brightness(0.5);
	/* Make image dull */
}

.profile-top {
	z-index: 9;
}

.profile-top input[type="file"] {
	display: none;
}

.wa-icon {
	width: 20px !important;
}

.watsapp-icon-width {
	width: 35px !important;
	height: 33px !important;
}

.profile-link-icon-css {
	max-width: 20px;
	height: 27px;
}

.cv-note-css {
	font-size: 12px;
	font-weight: 500;
}

.cv-note-css1 {
	font-weight: 600;
}

.d-height {
	height: 47px !important;
	margin-top: -23px !important;
}

.dropdown-item {
	background-color: transparent !important;
}

.default {
	border-color: #e7e7e7 !important;
}

.interest-btn {
	border-radius: 16px !important;
	line-height: 20px !important;
	width: 167px;
	font-size: 12px !important;
	box-shadow: 0px 1px 4px 0px #888888;
}

.profile-url {
	margin-top: -15px;
}

.new-profile-plus {
	padding-top: 0px;
	/* margin-top: 30px; */
}

.email-img {
	object-fit: cover;
	border-radius: 62px;
}

.nav-link.active {
	color: #0d6efd;
	/* margin: 0.5rem !important;  */
	background: none !important;
}

/* .job-modal-nav .nav-link {
	border: none !important;
}
 
.job-modal-nav .nav-link.active {
	border-bottom: 2px solid #0f52ba !important;
	border-radius: 0 !important;
} */

.job-modal-nav .nav-tabs {

	/* Customize the tab text color */
	font-weight: bold;
	border-bottom: 1px solid black !important;
	/* Make the tab text bold */
}

.job-modal-nav {
	border-bottom: 1px solid black !important;
	/* Bottom border for the tab bar */
}

.job-modal-nav .nav-link {
	/* Background color for tabs */
	border: 1px solid #dee2e6;
	/* Border for tabs */
	border-radius: 0.25rem;
	color: #000;
	/* Margin between tabs */

	/* Text color for tabs */
	font-weight: bold;
	/* Font weight for tabs */
	padding: 10px 20px;
	/* Padding inside tabs */
	transition: background-color 0.3s ease, color 0.3s ease;
	/* Smooth transition */
}

.job-modal-nav .nav-link.active {
	/* Background color for active tab */

	/* Text color for active tab */
	border-color: #007bff;
	color: #007bff;
	border-radius: 0.25rem;
	/* Border color for active tab */

}

.job-modal-nav .nav-link:hover {
	background-color: #e9ecef;
	/* Background color on hover */
}

.job-modal-nav .nav-item {
	margin: 0;
	/* Remove margin from nav items */
}

.nav-link {
	color:black;
	/* margin: 0.5rem !important; */
	margin-right: 0.5rem !important;
	/* margin-bottom: 0.5rem !important; */
}

.currentprfl {
	/* font-size: 14px; */
	font-weight: 600;
	color: black !important;
}

.page-status {
	padding: 0.3rem 0.8rem;
	position: relative;
	top: 6px;
}

.profile-num {
	padding: 0;
	position: absolute;
	margin-top: -29px;
	margin-left: -29px;
	cursor: pointer;
}

.profile-num .input-cls {
	border-radius: 7.8px !important;
	background-color: transparent;
	border-color: #0a0b0b;
}

.profile-num .arrow {
	padding-left: 5px;
	font-size: 20px;
}

.profile-nav {
	border: none !important;
	top: 6px;
	position: relative;
}

.fit-btn {
	line-height: 5px !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	color: #fff !important;
}

.profile-btn {
	line-height: 17px !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	color: #fff !important;
}

.circle-wrapper {
	position: relative;
	width: 60px;
	height: 60px;
	/* margin: auto; */
}

.circle-wrapper-bold {
	position: relative;
	width: 130px;
	height: 130px;
	/* margin: auto; */
}

.circle-progress-fit {
	--value: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.circle-progress {
	--value: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: conic-gradient(#4caf50 calc(var(--value) * 1%),
			#d9d9d9 calc(var(--value) * 1%));
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.circle-innerProgress {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background-color: white;
}

.circle-inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: white;
}

.percentage {
	font-size: 1rem;
	font-weight: bold;
}

.percentage-fit {
	font-size: 1.5rem;
	font-weight: bold;
}



.ql-container {
	height: 100px !important;
}

.interest-modal {
	height: 170px !important;
	overflow-y: auto;
}

.interest-back {
	position: absolute;
	left: 15px;
}

.section-hide {
	position: absolute;
	right: 86px;
}

.slider-count {
	top: -10px;
	position: relative;
}

.slider-count-edit {
	top: -4px;
	position: relative;
}

.profile-cover-image {
	width: 100%;
	height: 200px;
	border-radius: 15px 15px 0px 0px;
}

.profile-deatils-view {
	background-color: #fff !important;
	/* border: 0.5px solid #f8f7f7c0; */
	border-radius: 0.78em;
	margin-bottom: 20px;
	padding: 15px;
	font-size: 13px;
	overflow: overlay;
}

.border-radious-css {
	border-top-left-radius: 0 !important;
}

.bg-img {
	height: 130px;
	background-image: url(../back-cover.png);
	/* background-image: url(../product-4.jpg); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.box-shadow {
	box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.profile-deatils-view a {
	border: none !important;
	padding-left: 0px;
}

.profile-deatils-view label {
	margin-bottom: 0;
	margin-left: 0;
	font-weight: 600;
	color: #000;
}

.nav-tabs {
	/* border-bottom: 1px solid #ced4da !important; */
	border-bottom: none !important;
	padding-bottom: 15px;
	padding-right: 15px !important;

}

.nav-tabs .nav-link.active {
	/* color: #495057; */
	background-color: #fff !important;
	border: none;
	border-color: #ced4da #ced4da #ced4da;
	border-radius: 0.78em 0.78em 0 0;
	/* padding: 15px !important; */
}

.nav-tabs .nav-link.activetab {
	/* color: #495057; */
	background-color: #fff !important;
	border: none;
	border-color: #ced4da #ced4da #ced4da;
	border-radius: 0.78em 0.78em 0 0;
	padding: 15px !important;
	padding-top: 0px !important;
	/* margin-bottom: 10px; */
}

.margin-position {
	position: relative;
	margin-top: -5px;
}

.profile-nav-tabs {
	/* top: 22px;  */
	position: relative;
	/* border-top-left-radius: 0;
	border-top-right-radius: 0; */
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top: 0;
	box-shadow: 0 1.5rem 1rem rgba(0, 0, 0, .15) !important;
}

.aboutme-brief {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin-top: -38px;
}

.profile-name-details {
	/* height: 112px; */
	margin: 0 0 0 0 !important;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom: 0;
	/* box-shadow: 0 1rem 10rem rgba(0, 0, 0, 0.4) !important; */
}

.profile-label {
	text-align: center;
}

.profile-name-details .active label {
	font-weight: 700;
}


.profile-nav-tabs+.tab-content {
	/* top: -18px; */
	position: relative;
}

.sub-tab-date {
	font-size: 11px;
}

#leftMenuDiv .profile-data {
	/* margin-left: 35%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#leftMenuDiv .card-body .card-text {
	text-align: right;
	margin-top: -5px;
}

#leftMenuDiv .profile-data h5 {
	/* text-align: end; */
	overflow: hidden;
	font-size: 15px;
	margin-bottom: 0;
	font-weight: 700;
}

.profile-view-container {
	padding: 0 2%;
}

.profile-view-container .profile-pic {
	top: 67px;
}

.profile-view-container .cover.profile-deatils-view {
	padding: 0;
	/* height: 270px; */
	height: 250px;
}

.profile-view-container .profile-name {
	text-align: right;
	padding: 10px;
	position: relative;
	top: 125px;
	left: 2px;
}

.profile-view-container .profile-name * {
	margin-bottom: 0;
}

.profile-view-container .profile-name h4 {
	font-size: 16px;
}

.profile-view-container .profile-deatils-view p {
	margin-bottom: 0;
}

.profile-name-details .profile-label {
	cursor: pointer;
}

.profile-name-details .profile-label label {
	cursor: pointer;
	margin-top: 5px;
	padding: 5px;
}

.profile-name-details .profile-label input {
	cursor: pointer;
	margin-top: 40px;
	/* padding-top: 5px; */
}

/* .nav-tabs .nav-link.active {

	padding: 0px;

} */

@media (max-width: 1060px) and (min-width: 769px) {
	.profile-top.col-lg-3 {
		width: 40% !important;
	}

	.active-profile-view.col-lg-9 {
		width: 60% !important;
	}

	.profile-label {
		width: auto !important;
	}
}

@media (min-width: 1061px) {
	.profile-top.col-lg-3 {
		width: 32% !important;
	}

	.active-profile-view.col-lg-9 {
		width: 68% !important;
	}

	.profile-label {
		width: auto !important;
	}
}

@media (min-width: 1200px) {
	.profile-top.col-lg-3 {
		width: 30% !important;
	}

	.active-profile-view.col-lg-9 {
		width: 70% !important;
	}

	.profile-label {
		width: auto !important;
	}
}

@media (min-width: 1310px) {
	.profile-top.col-lg-3 {
		width: 26% !important;
	}

	.active-profile-view.col-lg-9 {
		width: 74% !important;
	}

	.profile-label {
		width: auto !important;
	}
}

.profile-gradient-btn {
	line-height: 20px !important;
	background-image: linear-gradient(to right, #4EC5C1 13%, #1A73E8 50%) !important
}

.int-cat-img {
	max-width: 100%;
}

.int-bg {
	background-color: #fff !important;
	border: 0.5px solid #f8f7f7c0;
	border-radius: 0.78em;
	padding: 5px 0px;
	box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.25) !important;
	/* margin-left: 14px !important; */
}

.int-card {
	padding: 5px 15px;
}

.int-sub-card {
	padding: 5px 39px;
}

.sub-int-title {
	/* margin-left: 19px; */
	font-size: 15px;
	font-weight: 700;
}

.delete-profile {
	margin-left: 31px;
	position: absolute;
	/* margin-top: -13px; */
	opacity: 1.0;
	color: #c9ced8;
}

.btn-light {
	background-color: transparent !important;
}

.btn-light:hover {
	background-color: transparent !important;
	color: #000 !important;
}

.interest-scroll {
	overflow-y: auto;
	max-height: 70vh;
}

.map-icon {
	margin-right: 7px;
	margin-top: 4px;
}

.about-me-weight {
	font-weight: 700;
}

.margin-top-14px {
	margin-top: -14px;
}

.int-h .modal-dialog {
	margin-top: 0px !important;
	margin-bottom: 0 !important;
	width: 100% !important;
}

.interest-inside-scroll {
	overflow-y: auto;
	max-height: 152px;
}

.int-bg-card {
	margin-left: -12px;
}

.sub-title {
	margin-top: 5px;
}

.date {
	border: 1px solid #9ea1a5 !important;
}

.interest-mod-times {
	position: absolute;
	display: flex;
	margin-top: 4px;
	padding-right: 10px;
	width: 100%;
	justify-content: end;
	/* left: 656px; */
}

input {
	font-size: 13px !important;
}

.su-title {
	position: absolute;
	top: 174px;
}

.interest-close-btn {
	background-color: #4285F4 !important;
	color: #fff !important;
	border-color: none !important
}

.view-edit-status {
	color: #fff
}

.int-h .modal-footer {
	box-shadow: 0 2px 8px #b0a9a9 !important;
}

.interest-btn-category {
	border-radius: 16px !important;
	line-height: 20px !important;
	width: 150px;
	background-color: white !important;
	font-size: 11px !important;
	box-shadow: 0px 1px 4px 0px #888888;
}

@media (min-width: 1110px) {
	.interest-btn-category {
		border-radius: 16px !important;
		line-height: 20px !important;
		width: 150px;
		background-color: white !important;
		font-size: 11px !important;
		box-shadow: 0px 1px 4px 0px #888888;
	}
}

.categories-height {
	height: auto;
	/* margin-left: 10px !important; */

}

.checkbox-container-off {
	display: inline;
}

.checkbox-container-off input {
	display: none;
}

.checkbox-container-off label {
	position: absolute;
	color: #6c757d !important;
	font-size: 12px;
	/* margin-left: -30px;
	margin-top: 17px; */
}

.checkbox-container-off label::before {
	display: inline-block;
	content: 'O';
	position: relative;
	top: 13px;
	left: -5px;
	width: 34px;
	height: 12px;
	padding: 0 0.5rem;
	font-size: 11px;
	line-height: 1.2rem;
	text-align: right;
	background-color: lightgrey;
	border-radius: 2rem;
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
	transition: all ease-in-out 0.3s;
	color: transparent;
}

.checkbox-container-off label::after {
	display: inline-block;
	content: '';
	position: absolute;
	left: -5px;
	top: 9.5px;
	width: 19px;
	height: 19px;
	border-radius: 1rem;
	background-color: #fff;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
	transition: all ease-in-out 0.3s;
}

.checkbox-container-off input:checked+label::before {
	content: 'I';
	text-align: left;
	color: transparent;
}

.checkbox-container-off input:checked+label::after {
	left: 1.1rem;
}

h5 .checkbox-container-off label::after {
	top: .1rem;
}

.checkbox-container1-off {
	display: inline;
	/* margin-left: -35px; */
	margin-left: -15px;
	margin-top: -15px;
}

.checkbox-container1-off input {
	display: none;
}

.checkbox-container1-off label {
	position: absolute;
	color: #6c757d !important;
	font-size: 12px;
}

.checkbox-container1-off label::before {
	display: inline-block;
	content: 'O';
	position: relative;
	top: 13px;
	left: -5px;
	width: 34px;
	height: 12px;
	padding: 0 0.5rem;
	font-size: 11px;
	line-height: 1.2rem;
	text-align: right;
	background-color: lightgrey;
	border-radius: 2rem;
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
	transition: all ease-in-out 0.3s;
	color: transparent;
}

.checkbox-container1-off label::after {
	display: inline-block;
	content: '';
	position: absolute;
	left: -5px;
	top: 9.5px;
	width: 19px;
	height: 19px;
	border-radius: 1rem;
	background-color: #fff;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
	transition: all ease-in-out 0.3s;
}

.checkbox-container1-off input:checked+label::before {
	content: 'I';
	text-align: left;
	color: transparent;
}

.white-color {
	color: white !important;
}

.bg-img-pencil {
	position: absolute;
	right: 15px;
	top: 13px;
	background-color: white;
	border-radius: 50%;
	padding: 3px;
}

.image-upload>input {
	display: none;
}

.profile-cover-img-view {
	width: 99.5%;
	height: 270px;
	display: none;
}

.img-pencil {
	/* position: absolute; */
	/* top: 203px;
	left: 142px; */
	color: #fff;
}

.profile-background .pencil-show {
	opacity: 0;
}

.profile-round-image .pencil-show1 {
	opacity: 0;
}

.profile-background:hover .pencil-show {
	opacity: 1;
}

.profile-round-image:hover .pencil-show1 {
	opacity: 1;
}

.pencil-show1 {
	font-size: 4px;
}

.star-left-menu {
	position: relative;
	left: 79px;
	top: -5px;
}

.tooltip-inner {
	max-width: 200px !important;
	padding: 0.25rem 0.5rem !important;
	color: white !important;
	text-align: center !important;
	background-color: #4285F4 !important;
	border-radius: 5px !important;
	border-color: black !important;
}

.hide-opacity {
	opacity: 0.4;
}

.card-labels-size {
	font-size: 13px !important;
}

.hide-text-position {
	margin-left: 3px !important;
	top: 10px !important;
	position: absolute !important;
}

.skill-scroll {
	max-height: 280px;
	position: relative;
	overflow-x: auto;
	margin-left: 0px;
}

.skill-endorse-scroll {
	max-height: 280px;
	position: relative;
	overflow-x: auto;
	margin-left: 0px;
}

.hide-width {
	width: 94%;
}

.checkbox-container1-off input:checked+label::after {
	left: 1.1rem;
}

.inner-hide-text-position1 {
	margin-left: 3px !important;
	top: 10px !important;
	position: absolute !important;
}

.inner-hide-text-position {
	margin-left: 3px !important;
	top: 10px !important;
	position: absolute !important;
}

.slider {
	height: 27px;
	width: 89px;
}

.small-toggle .checkbox-container-off label::before {
	width: 25px !important;
	height: 10px !important;
}

.small-toggle .checkbox-container-off label::after {
	width: 17px !important;
	height: 17px !important;
	top: 9px !important;
}

.small-toggle .checkbox-container label::before {
	width: 25px !important;
	height: 13px !important;
	left: -5px !important;
}

.small-toggle .checkbox-container label::after {
	width: 17px !important;
	height: 17px !important;
	top: 11px !important;
}

.small-toggle .small-toggle .checkbox-container-off label::before {
	width: 25px !important;
	height: 10px !important;
	top: 3px !important;
}

.skill-endorse-hide {
	margin-left: 3px;
	position: absolute;
	top: 9px;
}

.skill-endorse-unhide {
	margin-left: 3px;
	position: absolute;
	top: 9px;
}

.small-toggle .checkbox-container1-off input:checked+label::after {
	left: 0.3rem;
}

.small-toggle .checkbox-container-off input:checked+label::after {
	left: 0.3rem;
}

.small-toggle .checkbox-container1 input:checked+label::after {
	left: 0.3rem;
}

.small-toggle .checkbox-container input:checked+label::after {
	left: 0.2rem;
}

.profile-top input[type="text"],
.active-profile-view input[type="text"] {
	border-radius: 7.8px !important;
	background-color: transparent;
	border-color: #9ea1a5;
}

.ql-toolbar.ql-snow {
	border-radius: 7.8px !important;
	border-bottom: none !important;
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.ql-container.ql-snow {
	border-radius: 7.8px !important;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}

input[type='range'] {
	overflow: hidden;
	cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
}


input[type='range']::-webkit-slider-runnable-track {
	width: 50px !important;
	height: 4px;
	background: grey !important;
	border-radius: 7.8px !important;
}

input[type='range']::-webkit-slider-thumb {
	position: relative;
	height: 17px;
	width: 17px;
	margin-top: -6px;
	margin-bottom: -6px;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
	transition: all ease-in-out 0.3s;
}

.interest-hide-text {
	position: absolute;
	top: 9px;
	/* left: 30px; */
}

.interest-trash {
	margin-top: 12px;
	/* margin-left: 15px; */
	margin-left: 45px;
}

.type1-1 {
	background-image: linear-gradient(#632DD0, #3C05AC);
	color: white !important;
	border: none !important;
}

.type1-2 {
	background-image: linear-gradient(#E21F1F, #AE1111);
	color: white !important;
	border: none !important;
}

.type1-3 {
	background-image: linear-gradient(#22921E, #055102);
	color: white !important;
	border: none !important;
}

.type1-4 {
	background-image: linear-gradient(#B61FE2, #790F98);
	color: white !important;
	border: none !important;
}

.type1-5 {
	background-image: linear-gradient(#D29652, #A86215);
	color: white !important;
	border: none !important;
}

.type1-6 {
	background-image: linear-gradient(#33DFA5, #0B885E);
	color: white !important;
	border: none !important;
}

.type1-7 {
	background-image: linear-gradient(#FCAF2A, #C7820B);
	color: white !important;
	border: none !important;
}

.type1-8 {
	background-image: linear-gradient(#27BFD0, #1A8591);
	color: white !important;
	border: none !important;
}

.type1-9 {
	background-image: linear-gradient(#65D93D, #469B29);
	color: white !important;
	border: none !important;
}

.type1-10 {
	background-image: linear-gradient(#EF62C5, #AC1880);
	color: white !important;
	border: none !important;
}

.type1-11 {
	background-image: linear-gradient(#47038B, #2D0455);
	color: white !important;
	border: none !important;
}

.type1-12 {
	background-image: linear-gradient(#FF562A, #C23D1B);
	color: white !important;
	border: none !important;
}

.type1-13 {
	background-image: linear-gradient(#2a51ff, #3040ab);
	color: white !important;
	border: none !important;
}

.type1-14 {
	background-image: linear-gradient(#9299d8, #a395ff);
	color: white !important;
	border: none !important;
}

.type1-15 {
	background-image: linear-gradient(#d82aff, #3a48a5);
	color: white !important;
	border: none !important;
}

.type2-1 {
	background-image: linear-gradient(#8D6942, #50361A);
	color: white !important;
	border: none !important;
}

.type2-2 {
	background-image: linear-gradient(#2D48D0, #00178E);
	color: white !important;
	border: none !important;
}

.type2-3 {
	background-image: linear-gradient(#E2811F, #C26302);
	color: white !important;
	border: none !important;
}

.type2-4 {
	background-image: linear-gradient(#928A1E, #605900);
	color: white !important;
	border: none !important;
}

.type2-5 {
	background-image: linear-gradient(#FC2A47, #E3102D);
	color: white !important;
	border: none !important;
}

.type2-6 {
	background-image: linear-gradient(#0FC73A, #025716);
	color: white !important;
	border: none !important;
}

.type2-7 {
	background-image: linear-gradient(#D751F7, #9B13BB);
	color: white !important;
	border: none !important;
}

.type2-8 {
	background-image: linear-gradient(#C98644, #88490C);
	color: white !important;
	border: none !important;
}

.type2-9 {
	background-image: linear-gradient(#F8E648, #B7A400);
	color: white !important;
	border: none !important;
}

.type2-10 {
	background-image: linear-gradient(#DE793F, #A23B00);
	color: white !important;
	border: none !important;
}

.type2-11 {
	background-image: linear-gradient(#CF2C5A, #AA0C38);
	color: white !important;
	border: none !important;
}

.type2-12 {
	background-image: linear-gradient(#4C5EB7, #2A3A85);
	color: white !important;
	border: none !important;
}

.type2-13 {
	background-image: linear-gradient(#9299d8, #a395ff);
	color: white !important;
	border: none !important;
}

.type2-14 {
	background-image: linear-gradient(#d82aff, #3a48a5);
	color: white !important;
	border: none !important;
}

.type2-15 {
	background-image: linear-gradient(#2a51ff, #3040ab);
	color: white !important;
	border: none !important;
}

.more-about-tab {
	margin-left: 0px;
}

.interest-alert-times {
	position: absolute;
	top: 23px;
	right: 25px;
}

.interest-alert-text {
	color: black;
}

.modified-col-width {
	width: 43% !important;
}

.modified-col-width-edit {
	width: 37% !important;
}

.more-interest-scroll {
	overflow-x: scroll;
	max-height: 280px;
}

.training-edit-view-css {
	display: flex;
	flex-direction: row;
	gap: 0.5rem !important;
}

.profile-hide-view-css {
	display: flex;
	flex-direction: row;
	justify-content: end;
	gap: 4.5rem !important;
}

.border-bottom-css {
	border-bottom: 1px solid #dcdcdc !important;
	margin-bottom: 15px !important;
}

.training-title-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.title-hide-container {
	text-align: end;
	padding-right: 65px;
}

.trash-muted-css {
	color: #ebebeb !important;
}

.profile-card-border-bottom {
	border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color) !important;
}

.profile-heading-font {
	font-size: 15px !important;
	font-weight: 700 !important;
	color: #000 !important;
	margin-bottom: 15px;
}

.designation-title-css {
	font-weight: bold;
	font-size: 14px;
}

.company-name-css {
	font-size: 12px;
	color: #4285f4;
	font-weight: 500;
}

.job-role-css {
	font-size: 13px;
	font-weight: 500;
}

.job-desc-css {
	font-size: 14px;
	font-weight: 500;
	color: #595959;
}

.experience-date-css {
	color: #444444 !important;
	font-size: 12px;
	font-weight: 600;
	margin-right: 5px;
}

.skill-int-tab .nav-link {
	color: #000 !important;
}

.profile-deatils-view .card-header {
	border: none !important;
}

@media only screen and (max-width: 770px) {
	.row> {
		flex-shrink: 1 !important;
		width: 100%;
		max-width: 100%;
		padding-right: calc(var(--bs-gutter-x) * .5);
		padding-left: calc(var(--bs-gutter-x) * .5);
		margin-top: var(--bs-gutter-y);
	}

	.sections-date {
		padding-left: 22px !important;
	}

	.desc-resp {
		padding-left: 12px !important;
	}

	.skill-trash {
		margin-top: 13px;
		margin-left: 96px;
	}

	/* 
	.more-skill-trash {
		margin-top: -24px !important;
		left: 99px !important;
		position: relative;
	} */


	/* .job-trash {
		margin-left: 150px;
		margin-top: -315px;
	} */
}

;

@media only screen and (max-width: 768px) {
	/* [class*="interest-col"] {
		width: 100% !important;
	} */

	.profile-margin-top {
		margin-top: 20px;
	}
}

@media only screen and (max-width: 768px) {
	.hide-resp {
		padding-top: 5px;
		padding-bottom: 5px;
		/* margin-left: 80% !important; */

	}

	.hide-resp-1 {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-left: 75%;

	}

	.section1 {
		display: none;
	}

	.section2 {
		display: block;
	}
}

@media (min-width: 768px) {
	.section1 {
		display: block;
	}

	.section2 {
		display: none;
	}
}

@media (max-width: 576px) {
	.width-css {
		width: 100%;
	}
}

@media (max-width: 360px) {
	.interest-btn-category {
		width: 135px !important;
	}
}

@media only screen and (max-width: 319px) {
	.hide-resp {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-left: 65% !important;

	}

	.hide-resp-1 {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-left: 65%;
	}
}

.fontstyle-css {
	font-style: italic;
}