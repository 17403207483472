body {
  font-family: 'Open Sans' !important;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}
.modal-check-success{
  width: 50px;
  height: 50px;
  background-color: #d4edda;
  color: #28a745;
  font-size: 32px;
  margin-bottom:16px
}

.modal-check-failure{
  width: 50px;
  height: 50px;
  background-color: #f8d7da;
  color: #dc3545;
  font-size: 32px;
  margin-bottom:16px
}

.delete-margin {
  margin-right: 2.5rem;
}
.table-width{
  width: 225px !important;
}
.last-width{
  width: 50px !important;
}
.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.list-inline-item {
  display: inline-block;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.tj-modal .modal-content {
  border-radius: 0 px !important;
}

.tj-modal .modal-header {
  display: flex !important;
  text-align: center;
  padding: 1px 1px;
}

.tj-modal .modal-body {
  padding: 0px 24px;
  font-size: 16px;
}

.modal-body-padding {
  padding: 55px 90px !important;
  font-size: 13px;
}

.mx-left {
  margin-left: 0.5rem !important;
}

.err-msg-height {
  height: 55px;
}
.err-height {
  height: 16px;
}

.form-grp-padding {
  padding-right: 13px;
  padding-left: 12px;
}

.tj-modal #qboard-post .modal-body {
  padding: 0 90px;
}

.tj-modal .modal-body select {
  height: 30px;
  font-size: 13px;
  padding-top: 0;
  padding-bottom: 0;
}
.custom-checkbox input[type="radio"] {
  transform: scale(1.5); /* Increase size */
  margin-right: 25px;
  margin-top: 6px;
}
.custom-checkbox label {
  font-size: 16px !important;
  font-weight:500;
}

.modal .form-group {
  margin-bottom: 1rem;
}

.modal .requirement-preview .form-group {
	margin-bottom: 0;
}


.tj-modal .modal-header .modal-title {
  flex-grow: 1 !important;
}

label {
  /* margin-bottom: .5rem; */
  font-size: 14px;
}


.error-message {
  color: #dc3545 !important;
  font-size: 12px;
  margin-top: 1px;
}

.delete-modal .modal-header {
  border-bottom: none;
}

.delete-modal .modal-footer {
  justify-content: center !important;
  border-top: none;
}

.moreinfo {
  text-decoration: none;
}

.tj-modal .close {
  background-color: #fff !important;
  border: none !important;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;

}

.show-more li {
  padding: 5px 10px !important;
  cursor: pointer;
}

.choose_file {
  position: relative;
  display: inline-block;
}

.choose_file.disable {
  opacity: 0.3;
  pointer-events: none;
}


.absolute {
  position: absolute;
}

.hidden {
  /* The menu is hidden at first */
  display: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.date-view {
  font-family: 'Open Sans';
  opacity: 0.55;
  font-size: 11px !important;
}

.dull-star {
  color: gray;
}

.highlight-star {
  color: #F3D53D;
}

.invisible {
  visibility: hidden;
}

.dropdown-menu .dropdown-item svg {
  font-size: 20px;
  /* color: tomato; */
}

#tick-mark-check{
  font-size:14px;
  color: #20a20b;
  margin-right: 5px;
}

.image-enlarge-modal .modal-content {
  margin: auto;
  display: block;
  width: 100%;
  background: none;
    border: none;
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
  }
  
  @keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
  }

  .image-enlarge-modal .close {
  float:right;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  padding: 0 10px;
  }
  
  .image-enlarge-modal .close:hover,
  .image-enlarge-modal .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
  }
  

.form-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.form-row>.col,
.form-row>[class*=col-] {
	padding-right: 5px;
	padding-left: 5px;
}

.color-theme {
  text-align: end;
  padding: 0 60px;
 }
 .color-theme li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 9px 4px;
 }

 .color-theme li.active-color {
  top: 3.5px;
  position: relative;
  background-color: #fff !important;
 }
.job_overviewwidth{
  width: 35%;
  text-align: justify;
}
 .color-theme .active-color::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: currentColor 4px solid;
  top: -5px;
    left: -7.5px;
    position: relative;
 }

 .is-dark-theme {
   background: #000 !important;
 }

 .is-dark-theme .color-theme li.active-color {
  background: #000 !important;
 }

 .is-dark-theme .profile-dropdown a {
   color: #fff !important;
 }

  .feed-layout {
  padding-left: 0;
 } 

 .popup-layout {
  padding:0 15% !important;
 }

.common-expand-icon, .common-collapse-icon  {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #0000001f;
    background-color: #fff;
  }
  .common-collapse-icon {
    padding: 0 8px 1px 8px;
  }
  .common-expand-icon {
    padding: 0 7px 1px 8px;
  }

  .common-expand-icon svg, .common-collapse-icon svg {
    font-size: 12px;
  }

  .is-dark-theme  .common-expand-icon, .is-dark-theme .common-collapse-icon {
    background-color: #564f4f;
  }

.layout-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}
.sticky-column {
  position: sticky;
  top: 0;
  background-color: white;
  color: black;
  z-index: 1020;
}

.badge-color{
/* border: #007bff 2px solid; */
font-weight: bolder !important;
color: #007bff;
}
.dropdown-item.active, .dropdown-item:active{
  color:#007bff !important;
}
.dropdown-menu {
  cursor: pointer !important;
}
.custom-quill .ql-container {
  min-height: 300px;
}
.layout-left-side {
  position: sticky;
  top: 100px;
  z-index: 11;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 80vh;
}

.layout-right-side {
  position: sticky;
  top: 59px;
  z-index: 11px;
}

@media (max-width: 1024px) {
  .delete-margin{
    margin-right: 4rem;
  }
}

@media (max-width: 768px) {
  .delete-margin{
    margin-right: 2rem;
  }
}

@media (max-width: 425px) {
  .delete-margin{
    margin-right: 1.5rem;
  }
  /* .mob-col{
    width: 245px !important;
  } */
}

@media (max-width: 992px) { 
  .feed-layout {
    padding-left: 12px !important;
  }
}

body.is-dark-theme   {
  background-color: #000;
}

@media (max-width: 999px) { 
  .tj-modal .modal-body {
    padding: 5px 30px;
    font-size: 13px;
  }

  .tj-modal #qboard-post .modal-body {
    padding: 0 30px;
  }
}
