// $globalblue:#1d9bf0;
$globalblue: linear-gradient(256deg, #00c9e4 0%, #007bff 100%);
$globalblue-btn:#007bff;
$globalgreen:#34a853;
$globalgreen-btn:#34a853;
$globalpink:#9c007b;
$globalpink-btn:#9c007b;
$globalorange:#d24359;
$globalorange-btn:#d24359;
$globalpurple:#885ccd;
$globalpurple-btn:#885ccd;
//$globalorange:#c06c84;
//$globalorange:#fbbc05;
// $globalgreen:#6bbc9b;
//$globalpink:#ea4335;
// $globalpink:#9d5a8f;
// $globalorange:#ff7a00;

.global-blue {
	background-color: $globalblue;
	color: #fff !important;
	// background: $globalblue-btn;
}
.btn-global-blue {
  background-color: $globalblue-btn !important;
	border-color: $globalblue-btn !important;
	color: #fff !important;
}

.global-green {
	background-color: $globalgreen !important;
	border-color: $globalgreen-btn !important;
	color: #fff !important;
}

.btn-global-green {
  background-color: $globalgreen-btn !important;
	border-color: $globalgreen-btn !important;
	color: #fff !important;
}

.global-pink {
	background-color: $globalpink !important;
	border-color: $globalpink-btn !important;
	color: #fff !important;
}

.btn-global-pink {
  background-color: $globalpink-btn !important;
	border-color: $globalpink-btn !important;
	color: #fff !important;
}

.global-purple {
	background-color: $globalpurple !important;
	border-color: $globalpurple-btn !important;
	color: #fff !important;
}

.btn-global-purple {
  background-color: $globalpurple-btn !important;
	border-color: $globalpurple-btn !important;
	color: #fff !important;
}

.global-orange {
	background-color: $globalorange !important;
	border-color: $globalorange-btn !important;
	color: #fff !important;
}
.btn-global-orange {
  background-color: $globalorange-btn !important;
	border-color: $globalorange-btn !important;
	color: #fff !important;
}

.global-blue-right-border {
	border-right-color: $globalblue !important;
}
.global-green-right-border {
	border-right-color: $globalgreen !important;
}
.global-pink-right-border {
	border-right-color: $globalpink !important;
}
.global-purple-right-border {
	border-right-color: $globalpurple !important;
}
.global-orange-right-border {
	border-right-color: $globalorange !important;
}

.global-blue-font, .global-blue-nav a.active {
	color: $globalblue !important;
}
.global-green-font, .global-green-nav a.active {
	color: $globalgreen !important;
}
.global-pink-font, .global-pink-nav a.active {
	color: $globalpink !important;
}
.global-purple-font, .global-purple-nav a.active {
	color: $globalpurple !important;
}
.global-orange-font, .global-orange-nav a.active {
	color: $globalorange !important;
}

.global-green-icon img, .global-green-icon i {
	filter: brightness(100%) sepia(1)  hue-rotate(118deg) saturate(86.5%) brightness(98%);
}

.global-pink-icon img, .global-pink-icon i {
	filter: brightness(100%) sepia(1)  hue-rotate(282deg) saturate(74.5%) brightness(107%);
}

.global-purple-icon img, .global-purple-icon i {
	filter: brightness(100%) sepia(1)  hue-rotate(225deg) saturate(71.5%) brightness(98%);
}
.global-orange-icon img, .global-orange-icon i {
	filter: brightness(100%) sepia(1)  hue-rotate(38deg) saturate(124.5%) brightness(115%);
}

.category-select {
	width: 200px;
    color: #000;
	top: 2px;
    [class$="ValueContainer"] {
        min-height: 20px !important;
        max-height: 20px;
    }
    [class$="IndicatorsContainer"] {
        min-height: 20px !important;
        max-height: 20px;
    }
	[class$="indicatorSeparator"] {
      display: none;
    }
	[class$="indicatorContainer"] {
		position: relative;
		border-radius: .25rem;
		margin-left: -23px;
		padding: 10px;
		top: -4px;
		display: none;
	}
	[class$="indicatorContainer"]::before {
		font-family: 'FontAwesome';
		content: "\f0b0";
		left: 4px;
		position: absolute;
		top: 0;
	}
	[class$="indicatorContainer"] > svg  {
		display: none;
	}
    [class$="-input"] {
        min-height: 20px !important;
        max-height: 20px;
        padding: 0px;
    }
    [class$="-control"] {
        min-height: 20px !important;
        max-height: 20px;
		box-shadow: none;
    }
	[class$="-control"]:hover{
		border: none;
	}	
	[class$="-placeholder"] {
		top: 8px;

    }
	[class$="-singleValue"] {
		top: 8px;
	}

	[id="react-select-2-input"]	{
		top: -2px;
    	position: absolute;
	}
	[class$="option"] {
		color: #000 !important;
	}
}

.is-dark-theme .category-select {

	[class$="ValueContainer"], [class$="menu"],  [class$="-singleValue"],  [class$="-control"]{
		background-color: #000 !important;
		color: #fff;
	}
	[class$="option"] {
		color: #fff !important;
	}	

	input {
		background-color: transparent !important;
	}
}

.d-theme svg {
	position: absolute;
	font-size: 10px;
}

.is-dark-theme .d-theme svg {
	color: #fff !important;
}

.is-dark-theme .d-theme svg {
	color: #fff !important;
}