.enrolled-courses-page {
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.course-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.course-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: auto !important;
  overflow: hidden !important;
  height: 100%;
  transition: transform 0.2s;
}

.course-card .card-body {
  padding: 0 10px 20px 10px;
}

.course-card-img-top {
  height: 200px !important;
  object-fit: contain;
  border: 1px solid #f4f4f4;
  padding: 6px;
  border-radius: 6px;
}

.course-card:hover {
  transform: scale(1.05);
}

.course-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.course-details {
  padding: 15px;
}

.course-details h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.course-details p {
  font-size: 1em;
  margin-bottom: 5px;
  color: #666;
}

.online-hover {
  transition: visibility 0s, opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 20px;
  /* background-color: #007bff;  */
}

.course-card-content {
  display: flex;
  gap: .8rem;
  white-space: nowrap;
  flex-direction: column;
  flex: 1 1 auto; /* Explicitly set flex-basis */
  min-width: 1px;
}

.course-title-css {
  display: -webkit-box !important;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
}

.course-trainer-name-css {
  display: -webkit-box !important;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #595c73;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.course-price-div {
  align-items: center;
  color: #303141;
  display: flex;
  flex-flow: row wrap;
}

.course-price-css {
  padding: .4rem 0;
  margin-right: .8rem;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
}

.course-base-price-css {
  color: #595c73;
  padding: .4rem 0;
  margin-right: 0;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
}

.course-author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.course-author .thumb-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0px 3px 10px rgba(17, 32, 81, 0.08);
}

.course-description-css {
  display: -webkit-box !important;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-weight: 500;
  color: #898989;
  font-size: 14px;
  text-transform: capitalize;
}

.online-icon-css {
  width: 13px;
  height: 13px;
  color: #00a0f1;
}

.course-list-name-css {
  color: #848484;
  font-size: 11px;
  font-weight: 600;
  margin-left: 5px;
}

.course-language-css {
  font-weight: 600;
  /* color: #898989; */
  font-size: 11px;
}

.learn-more-css {
  font-size: 13px;
  margin-right: 5px;
  font-weight: 700;
  color: #00a0f3;
}

.course-venue-title {
  font-size: 14px;
  margin-right: 8px;
  font-weight: 500;
}

.course-venue {
  font-size: 14px;
  font-weight: 600;
}

.display-flex-css {
  display: flex;
  flex-direction: row;
}

.course-details-card {
  padding: 30px 30px 30px 30px;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
  border-radius: 5px;
}

.course-details-image {
  border-radius: 5px;
  height: 250px !important;
  object-fit: contain;
}

.cd-course-title {
  /* font-size: 40px; */
  font-weight: 700;
  word-break: break-word;
  color: #00170F;
  text-align: left;
}

.cd-course-description {
  font-size: 16px;
  font-weight: 500;
  color: #00170F;
}

.cd-mb-20 {
  margin-bottom: 20px;
}

.cd-mb-15 {
  margin-bottom: 15px;
}

.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bd-course-price {
  display: flex;
  gap: 0 10px;
  align-items: center;
}

.current-price {
  font-size: 30px;
  color: #00170F;
  font-weight: 700;
}

.old-price {
  font-size: 22px;
  font-weight: 700;
  color: #6c757d;
  text-decoration-line: line-through;
}

.course-details-author {
  /* display: flex; */
  align-items: center;
  gap: 15px 30px;
}

.course-details-author .thumb-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0px 3px 10px rgba(17, 32, 81, 0.08);
  margin-right: 15px;
}

.cd-traine-title {
  color: #4d4d4d;
  font-size: 13px;
  font-weight: 500;
}

.cd-traine-name {
  color: #00170F;
  font-size: 15px;
  font-weight: 600;
}

.cd-content-title {
  font-size: 20px;
  font-weight: 700;
}

.bd-course-sidebar-widget-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 12px;
}

.bd-course-sidebar-widget-list ul li .icon {
  display: flex;
  align-items: center;
}

.bd-course-sidebar-widget-list ul li .icon i {
  line-height: 1;
  margin-right: 15px;
  color: #808080;
}

.cd-course-details-content {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
  margin-bottom: 30px;
  line-height: 30px;
}

.cd-course-add-time {
  /* display: flex; */
  flex-wrap: wrap;
  align-items: center;
  gap: 15px 30px;
}

.cd-course-add-time-item p {
  font-size: 15px;
  color: #0A0A0A;
  font-weight: 500;
}

.cd-course-add-time-item .icon {
  height: 30px;
  width: 30px;
  text-align: center;
  display: inline-flex;
  background-color: #4e8df5;
  margin-right: 8px;
  border-radius: 100%;
  color: #fff;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.cd-course-add-time-item .cd-date {
  color: #00170F;
  font-weight: 700;
  margin-left: 10px;
}


.bd-course-sidebar-widget {
  padding: 30px 30px 30px 30px;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
  border-radius: 6px;
}


.sidebar-sticky {
  position: sticky !important;
  top: 120px;
}

.p-relative {
  position: relative;
}

.bd-course-sidebar-widget-thumb {
  border: 1px solid #f4f4f4;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
}

.bd-course-sidebar-widget-thumb img {
  border-radius: 8px;
  object-fit: contain;
  height: 220px;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.bd-course-sidebar-widget-btn .bd-btn {
  padding: 0 20px;
}
.active-bd-btn {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 50px;
  border-radius: 6px;
  white-space: nowrap;
}

.bd-btn {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  background-color: #4e8df5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 50px;
  border-radius: 6px;
  white-space: nowrap;
}

.bd-course-feature-box .bd-course-details-content-title {
  margin-bottom: 30px;
}

.bd-course-details-content-title {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 10px;
}

.cd-mb-30 {
  margin-bottom: 30px;
}

.cd-mt-30 {
  margin-top: 30px;
}

.checkbox-css .form-control {
  background: transparent;
  border: none !important;
  height: auto;
  margin-bottom: 14px;
  padding: 0;
  position: relative;
  width: auto;
}

.card-custom-style {
  background-color: #fff;
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

.bd-course-feature-box {
  /* display: flex; */
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #646363;
}

.bd-course-feature-box p {
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #646363;
}

.bd-course-feature-box h1,
.bd-course-feature-box h2,
.bd-course-feature-box h3,
.bd-course-feature-box h4 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 30px;
}

.list-icon {
  color: #fff;
  background: #4e8df5;
}

.card-detais-about {
  border-bottom: 1px solid #a4a4a4;
  padding-bottom: 15px;
}

.cd-about-title {
  font-weight: 700;
}

@media (min-width: 1061px) {
  .cd-col-width .col-lg-4 {
    width: 34% !important;
  }

  .cd-col-width .col-lg-8 {
    width: 66% !important;
  }
}

@media (max-width: 1060px) and (min-width: 769px) {

  .cd-col-width .col-lg-4 {
    width: 40% !important;
  }

  .cd-col-width .col-lg-8 {
    width: 60% !important;
  }
}

@media (max-width: 768px) {

  .cd-flex-column-reverse {
    flex-direction: column-reverse;
  }

  .cd-img-mt-30 {
    margin-top: 30px;
  }
}